import React from 'react'
import './partTwo.css'
import { Lo } from '../../assets/images/lo'
import { Me } from '../../assets/images/me'
import { Skv } from '../../assets/images/skv'

import { S } from '../../assets/images/s'
import { A } from '../../assets/images/a'

const PartTwo = () => {
  const isSmallScreen = window.innerWidth < 768

  return (
    <div className="about-part-two">
      <div className="s-charTwo">{S}</div>
      <div className="a-charTwo">{A}</div>
      <div className="about-part-two-text">
        <p>
          I am Salome, a design maverick blending the allure of the past with
          the innovation of tomorrow.
        </p>
        <p>
          founder of Oeuvre Design Studio, and an Integrated Design Director
          based in vibrant Tbilisi, Georgia, with a global reach.
        </p>
      </div>
      <div className="first-section">
        <p className="paragraph-one">don’t take it too seriously, but still</p>
        <p className="paragraph-two">
          keep it scrolling {isSmallScreen ? '' : 'to the right'}
        </p>
        <div className="skv-char skv-first">{Skv}</div>
      </div>
      <div className="second-section">
        <div className="lo-char">{Lo}</div>
        <div className="me-char">{Me}</div>
        <div className="skv-char skv-second">{Skv}</div>
      </div>
    </div>
  )
}

export default PartTwo
