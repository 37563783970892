export const whiteLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="637.157"
    // height="99.109"
    viewBox="0 0 637.157 99.109"
  >
    <g
      id="Group_3"
      data-name="Group 3"
      transform="translate(-392.096 -739.381)"
    >
      <g
        id="Group_1"
        data-name="Group 1"
        transform="translate(392.711 740.427)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M392.711,803.79l22.828-2.146a11.252,11.252,0,0,0,3.955,5.819,11.744,11.744,0,0,0,6.781,1.751q4.631,0,7.176-1.975a4.41,4.41,0,0,0,1.977-3.666,4.2,4.2,0,0,0-2.6-3.837q-1.864-.96-9.888-2.368-11.982-2.091-16.641-3.867a17.552,17.552,0,0,1-7.854-6.01,15.61,15.61,0,0,1-3.192-9.653,15.816,15.816,0,0,1,3.445-10.219,20.514,20.514,0,0,1,9.493-6.406q6.047-2.114,16.217-2.117,10.734,0,15.848,1.639a20.69,20.69,0,0,1,8.531,5.084,26.632,26.632,0,0,1,5.68,9.323l-21.809,2.148a7.465,7.465,0,0,0-2.826-4.239,11.563,11.563,0,0,0-6.555-1.808q-3.9,0-5.68,1.385a4.124,4.124,0,0,0-1.779,3.356,3.577,3.577,0,0,0,2.26,3.328q2.259,1.128,9.832,2.033,11.467,1.293,17.063,3.612a18.809,18.809,0,0,1,8.559,6.6,16.215,16.215,0,0,1,2.968,9.427,18.53,18.53,0,0,1-3.137,10.1q-3.136,4.911-9.886,7.818t-18.392,2.906q-16.442,0-23.422-4.688A21.087,21.087,0,0,1,392.711,803.79Z"
          transform="translate(-392.711 -724.251)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-width="1"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M451.441,779.889l-21.979-2.316a24.668,24.668,0,0,1,3.587-9.067,19.762,19.762,0,0,1,6.753-5.737,28.31,28.31,0,0,1,8.7-2.712,70.351,70.351,0,0,1,11.979-.959,95.125,95.125,0,0,1,16.611,1.159,21.337,21.337,0,0,1,10.454,4.83,16.946,16.946,0,0,1,4.632,7.2,26.024,26.024,0,0,1,1.695,8.9v26.5a31.9,31.9,0,0,0,.538,6.637,29.874,29.874,0,0,0,2.344,6.133H475.172a21.948,21.948,0,0,1-1.695-3.533,25.9,25.9,0,0,1-.791-3.813,29.74,29.74,0,0,1-8.985,6.215,37.4,37.4,0,0,1-14.181,2.486q-10.737,0-16.3-4.972a15.8,15.8,0,0,1-5.566-12.26,16.074,16.074,0,0,1,4.011-11.245q4.012-4.407,14.8-6.553,12.94-2.6,16.781-3.645a78.693,78.693,0,0,0,8.136-2.74q0-4.239-1.751-5.933t-6.159-1.695q-5.65,0-8.475,1.809Q452.8,775.99,451.441,779.889Zm19.945,12.093q-4.745,1.694-9.886,2.994-7.007,1.865-8.871,3.673a5.879,5.879,0,0,0-.028,8.673,8,8,0,0,0,5.564,1.723,14.316,14.316,0,0,0,7.15-1.865A11.3,11.3,0,0,0,470,802.633a15.24,15.24,0,0,0,1.383-6.979Z"
          transform="translate(-362.436 -724.251)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-width="1"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M498.827,836.15v.485H472.984l1.092-7.523V757.047c0-4.854-3.64-4.246-11.525-1.941v-.728l29.967-13.952-.607,5.823v78.616C491.912,831.419,492.761,834.816,498.827,836.15Z"
          transform="translate(-335.2 -740.647)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M547.3,790.266c0,17.836-12.617,30.21-32.758,30.21s-32.756-11.889-32.756-30.21c0-17.834,12.617-30.208,32.756-30.208S547.3,771.947,547.3,790.266ZM530.56,800.457c0-16.014-6.307-38.338-20.745-38.338-8.25,0-11.282,6.915-11.282,18.2,0,15.771,6.432,38.094,20.745,38.094C527.527,818.412,530.56,811.377,530.56,800.457Z"
          transform="translate(-318.531 -723.639)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M519.534,760.452h21.471v8.759a30.028,30.028,0,0,1,9.364-7.8,25.708,25.708,0,0,1,11.4-2.316q7.184,0,11.372,2.542a18.908,18.908,0,0,1,6.846,7.572q5.417-5.876,9.875-8a25.587,25.587,0,0,1,11-2.117q9.648,0,15.064,5.734t5.416,17.94v37.689H598.3V786.274q0-4.068-1.577-6.045a6.927,6.927,0,0,0-5.743-3.108,8.131,8.131,0,0,0-6.532,2.938q-2.478,2.94-2.477,9.437v30.964H558.915V787.4a20.723,20.723,0,0,0-.453-5.368,7.336,7.336,0,0,0-2.551-3.645,6.8,6.8,0,0,0-4.25-1.385,8.2,8.2,0,0,0-6.523,3q-2.55,3-2.551,9.831v30.626H519.534Z"
          transform="translate(-285.829 -724.471)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-width="1"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M645.8,796.162H599.695q.621,5.54,3,8.25a10.9,10.9,0,0,0,8.7,3.9,13.079,13.079,0,0,0,6.441-1.695,15.142,15.142,0,0,0,4.013-3.787l22.657,2.09q-5.2,9.043-12.542,12.97t-21.076,3.925q-11.923,0-18.759-3.361a27.54,27.54,0,0,1-11.329-10.68,32.235,32.235,0,0,1-4.493-17.2q0-14.068,9.013-22.77t24.889-8.7q12.884,0,20.342,3.9A26.329,26.329,0,0,1,641.9,774.3q3.9,7.405,3.9,19.267Zm-23.394-11.018q-.677-6.666-3.587-9.548a11.258,11.258,0,0,0-16.413,1.469q-2.094,2.71-2.656,8.08Z"
          transform="translate(-236.643 -724.471)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-width="1"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M614.156,813.318l8.128-12.981h.243c-.485,10.555,10.435,17.955,20.869,17.955,6.672,0,10.193-2.671,10.193-8.373,0-7.036-5.34-10.555-16.137-12.01-18.442-2.184-22.2-13.224-22.2-20.139,0-11.04,10.191-17.712,25.113-17.712,9.948,0,16.984,3.033,21.352,7.279l-8.614,11.4h-.243c.849-9.218-7.643-16.863-16.863-16.863-8.007,0-8.856,5.461-8.856,8.007,0,7.038,5.7,10.071,14.315,11.648,11.284,2.184,22.324,7.158,22.324,19.532,0,11.163-9.948,19.413-24.993,19.413C629.565,820.476,618.887,817.32,614.156,813.318Z"
          transform="translate(-203.846 -723.639)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M689.995,836.635v-2.064l-22.083-25.841h-.6v16.135c0,6.553.847,9.95,6.915,11.284v.485H648.381l1.092-7.523V757.047c0-4.854-3.641-4.246-11.527-1.941v-.728l29.965-13.952-.6,5.823v61.51l23.657-23.414c2.305-2.305,3.52-4.855-2.426-5.823l.243-.73h21.354l-.243.73c-5.1.6-13.347,3.518-23.78,13.951l-6.915,6.915,18.321,21.232c8.492,9.582,11.766,12.858,18.074,16.014Z"
          transform="translate(-183.234 -740.647)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M740.484,760.447v.485c-3.884,1.456-6.432,3.763-11.283,14.2l-21.111,45.737h-.849l-25.6-52.168-5.217-8.25h31.544v.485c-6.066.73-11.406,3.64-6.308,14.075l12.981,26.328,12.253-26.328c4.731-10.435-.484-12.619-4.851-14.075v-.485Z"
          transform="translate(-149.895 -723.301)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
      <g id="Group_406" data-name="Group 406">
        <g
          id="Group_2"
          data-name="Group 2"
          transform="translate(998.301 763.752)"
        >
          <path
            id="Path_10"
            data-name="Path 10"
            d="M717.177,766.6l2.376-.855c.381,1.021,1.685,1.734,3.942,1.734,2.923,0,4.134-.974,4.11-2.327-.022-1.592-1.331-1.971-4.11-2.542-3.656-.76-6.057-1.829-6.008-4.87.048-2.708,2.447-4.821,6.008-4.821,2.923,0,5.013,1.329,5.726,3.421l-2.376.855c-.356-1.045-1.5-1.807-3.326-1.807-2.281,0-3.561,1.14-3.49,2.376.022,1.377,1.331,1.8,4.037,2.376,3.729.76,6.057,1.851,6.057,5.011,0,2.945-2.447,4.821-6.628,4.821C720.1,769.977,718.056,768.742,717.177,766.6Z"
            transform="translate(-717.177 -752.924)"
            fill="#fcfcfc"
            stroke="#fcfcfc"
            stroke-width="1"
          />
        </g>
        <path
          id="Path_11"
          data-name="Path 11"
          d="M738.693,756.836c-.019.037-.037.071-.054.1l-4.668,9.338a1.162,1.162,0,0,1-2.1.041q-1.086-2.167-2.167-4.334l-2.525-5.051-.052-.1a.2.2,0,0,0-.017.125V769.1c0,.162,0,.162-.159.162h-2.038c-.138,0-.123.017-.123-.121V756.78c0-.86,0-1.723,0-2.583a1.16,1.16,0,0,1,.752-1.114.791.791,0,0,1,.28-.052c.424,0,.847,0,1.273,0a1.13,1.13,0,0,1,1.073.664c.233.459.463.922.692,1.383q2,3.989,3.99,7.981c.017.034.034.067.058.11.024-.047.043-.082.062-.12q2.329-4.659,4.659-9.317a1.176,1.176,0,0,1,.872-.687.629.629,0,0,1,.133-.013c.442,0,.886-.009,1.329,0a1.153,1.153,0,0,1,1.051.974,2.251,2.251,0,0,1,.015.248v14.868c0,.134,0,.134-.129.134h-2.057c-.151,0-.134.026-.134-.14v-6.9q0-2.635,0-5.271A.17.17,0,0,0,738.693,756.836Z"
          transform="translate(287.722 10.92)"
          fill="#fcfcfc"
          stroke="#fcfcfc"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
)
