export const A = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70.4"
    height="63.717"
    viewBox="0 0 70.4 63.717"
  >
    <path
      id="Path_2"
      dataname="Path 2"
      d="M451.441,779.889l-21.979-2.316a24.668,24.668,0,0,1,3.587-9.067,19.762,19.762,0,0,1,6.753-5.737,28.31,28.31,0,0,1,8.7-2.712,70.351,70.351,0,0,1,11.979-.959,95.125,95.125,0,0,1,16.611,1.159,21.337,21.337,0,0,1,10.454,4.83,16.946,16.946,0,0,1,4.632,7.2,26.024,26.024,0,0,1,1.695,8.9v26.5a31.9,31.9,0,0,0,.538,6.637,29.874,29.874,0,0,0,2.344,6.133H475.172a21.948,21.948,0,0,1-1.695-3.533,25.9,25.9,0,0,1-.791-3.813,29.74,29.74,0,0,1-8.985,6.215,37.4,37.4,0,0,1-14.181,2.486q-10.737,0-16.3-4.972a15.8,15.8,0,0,1-5.566-12.26,16.074,16.074,0,0,1,4.011-11.245q4.012-4.407,14.8-6.553,12.94-2.6,16.781-3.645a78.693,78.693,0,0,0,8.136-2.74q0-4.239-1.751-5.933t-6.159-1.695q-5.65,0-8.475,1.809Q452.8,775.99,451.441,779.889Zm19.945,12.093q-4.745,1.694-9.886,2.994-7.007,1.865-8.871,3.673a5.879,5.879,0,0,0-.028,8.673,8,8,0,0,0,5.564,1.723,14.316,14.316,0,0,0,7.15-1.865A11.3,11.3,0,0,0,470,802.633a15.24,15.24,0,0,0,1.383-6.979Z"
      transform="translate(-427.153 -758.597)"
      fill="#151515"
      stroke="#151515"
      strokeWidth="1"
    />
  </svg>
);
