import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './scrollingText.css'

import { Autoplay } from 'swiper/modules'

const ScrollingText = ({ title }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  let bottomStyle = '1180px'

  if (title.length <= 6) {
    if (windowHeight <= 900) {
      // bottomStyle = '650px'
      bottomStyle = '390px'
    } else {
      bottomStyle = '600px'
    }
  } else {
    if (windowHeight <= 900) {
      bottomStyle = '800px'
    } else {
      bottomStyle = '1180px'
    }
  }

  let bottomStyleLeft = '1180px'

  if (title.length <= 6) {
    if (windowHeight <= 900) {
      // bottomStyleLeft = '-130px'
      bottomStyleLeft = '-320px'
    } else {
      bottomStyleLeft = '-325px'
    }
  } else {
    if (windowHeight <= 900) {
      bottomStyleLeft = '-300px'
    } else {
      bottomStyleLeft = '-320px'
    }
  }

  const isSmallScreen = window.innerWidth < 768
  let spaceBetween

  if (title.length <= 6) {
    if (isSmallScreen) {
      spaceBetween = 100
    } else {
      spaceBetween = 500
    }
  } else {
    if (isSmallScreen) {
      spaceBetween = 300
    } else {
      spaceBetween = 900
    }
  }

  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={spaceBetween}
        centeredSlides={true}
        slidesPerView={1}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
          reverseDirection: isSmallScreen ? true : false,
        }}
        speed={4000}
        navigation={false}
        modules={[Autoplay]}
        className="mySwiper"
        direction={isSmallScreen ? 'horizontal' : 'vertical'}
      >
        <SwiperSlide>
          <div className="landing-title-container">
            <hr
              className={`${isSmallScreen ? 'mobile-hr' : 'pc-hr'}`}
              style={{ bottom: !isSmallScreen && bottomStyleLeft }}
            />
            <h1 className="landing-title">{title}</h1>
            {!isSmallScreen && (
              <hr
                className={`${isSmallScreen ? 'mobile-hr' : 'pc-hr'}`}
                style={{ bottom: !isSmallScreen && bottomStyle }}
              />
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="landing-title-container">
            <hr
              className={`${isSmallScreen ? 'mobile-hr' : 'pc-hr'}`}
              style={{ bottom: !isSmallScreen && bottomStyleLeft }}
            />
            <h1 className="landing-title">{title}</h1>
            {!isSmallScreen && (
              <hr
                className={`${isSmallScreen ? 'mobile-hr' : 'pc-hr'}`}
                style={{ bottom: !isSmallScreen && bottomStyle }}
              />
            )}
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default ScrollingText
