export const S = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.907"
    height="63.717"
    viewBox="0 0 64.907 63.717"
  >
    <path
      id="Path_1"
      dataname="Path 1"
      d="M392.711,803.79l22.828-2.146a11.252,11.252,0,0,0,3.955,5.819,11.744,11.744,0,0,0,6.781,1.751q4.631,0,7.176-1.975a4.41,4.41,0,0,0,1.977-3.666,4.2,4.2,0,0,0-2.6-3.837q-1.864-.96-9.888-2.368-11.982-2.091-16.641-3.867a17.552,17.552,0,0,1-7.854-6.01,15.61,15.61,0,0,1-3.192-9.653,15.816,15.816,0,0,1,3.445-10.219,20.514,20.514,0,0,1,9.493-6.406q6.047-2.114,16.217-2.117,10.734,0,15.848,1.639a20.69,20.69,0,0,1,8.531,5.084,26.632,26.632,0,0,1,5.68,9.323l-21.809,2.148a7.465,7.465,0,0,0-2.826-4.239,11.563,11.563,0,0,0-6.555-1.808q-3.9,0-5.68,1.385a4.124,4.124,0,0,0-1.779,3.356,3.577,3.577,0,0,0,2.26,3.328q2.259,1.128,9.832,2.033,11.467,1.293,17.063,3.612a18.809,18.809,0,0,1,8.559,6.6,16.215,16.215,0,0,1,2.968,9.427,18.53,18.53,0,0,1-3.137,10.1q-3.136,4.911-9.886,7.818t-18.392,2.906q-16.442,0-23.422-4.688A21.087,21.087,0,0,1,392.711,803.79Z"
      transform="translate(-392.096 -758.597)"
      fill="#151515"
      stroke="#151515"
      strokeWidth="1"
    />
  </svg>
);
