import React from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function SampleNextArrow(props) {
  const { onClick, smText } = props
  return (
    <div className="view-projects-wrapper">
      <Link to={`/portfolio`} className="view-projects">
        {smText}
      </Link>
      <div className="arrow-wrap" onClick={onClick}>
        <div className="arrow-landing"></div>
      </div>
    </div>
  )
}

const Carousel = ({ smText, img1, img2, img3 }) => {
  const isSmallScreen = window.innerWidth < 768

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow smText={smText} />,
    swipeToSlide: true,
    verticalSwiping: isSmallScreen ? false : true,
    vertical: isSmallScreen ? false : true,
    swipe: true,
    touchMove: true,
  }
  return (
    <>
      <div className="landing-image-container">
        <div className="slider-container">
          <Slider {...settings}>
            <LazyLoadImage effect="blur" alt="s" srcSet={img1} />
            <LazyLoadImage effect="blur" alt="s" srcSet={img2} />
            <LazyLoadImage effect="blur" alt="s" srcSet={img3} />
          </Slider>
        </div>
      </div>
    </>
  )
}

export default Carousel
