import React from 'react'
import './footer.css'
import Blob from './Blob'

const Footer = ({ isValid, id }) => {
  return (
    <div className="footer-container">
      <div className="footer-first-section">
        <div className="wanna-be">
          <p>
            {' '}
            wanna <span className="simp">be</span>
          </p>
          <p className="start">
            <span>start</span>ing
          </p>
          <p className="something">
            <span>something</span>?
          </p>
        </div>
        <div className="back-email-container">
          <GoBack isValid={isValid} id={id} />
          <p className="footer-text-two-mobile">I am almost everywhere.</p>
          <div className="footer-blob">
            <p className="footer-text-three-mobile">
              so feel free to pick which mode of communication works for you.
            </p>
            <Blob />
          </div>
          <Email />
        </div>
      </div>
      <div className="footer-second-section">
        <p className="footer-text-two">I am almost everywhere.</p>
        <p className="footer-text-three">
          so feel free to pick which mode of communication works for you.
        </p>
        {/* <div className="credits">
          <p className="footer-text-four">Coded with By Rodion.</p>
          <FaPeace />
        </div> */}
      </div>
    </div>
  )
}

const GoBack = ({ isValid, id }) => {
  return (
    <div className="go-back-wrapper">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        {isValid && (
          <div className="go-back">
            <p
              onClick={() => {
                const element = document.getElementById(id)
                if (element) {
                  element.scrollIntoView({
                    behavior: 'smooth',
                  })
                }
              }}
            >
              start over
            </p>
            <div className="arrow-wrapper">
              <div className="footer-arrow"></div>
            </div>
          </div>
        )}
        <div
          className="footer-circle"
          style={{ marginLeft: isValid ? '16px' : '120px' }}
        ></div>
      </div>
      {/* <div className="credits-mobile">
        <p className="footer-text-four-mobile">Coded with By Rodion.</p>
        <FaPeace />
      </div> */}
    </div>
  )
}

const Email = () => {
  return (
    <div className="email-section">
      <p className="footer-text-one">
        just send an email to start a discussion
      </p>
      <div className="email-part">
        <a href="mailto:hey@salomeskv.com">hey@salomeskv.com</a>
        <div className="email-arrow"></div>
      </div>
    </div>
  )
}
export default Footer
