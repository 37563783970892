import React from 'react'
import './partThree.css'
import Card from '../../assets/images/highlights/Ss.jpeg'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const PartThree = () => {
  return (
    <div className="about-part-three">
      <div className="aside-picture-container">
        <div className="one"></div>
        <h3>brief history of me</h3>
        <LazyLoadImage alt="two" className="two" srcSet={Card} />
        <div className="pic-three">
          <div className="three"></div>
        </div>
      </div>
      <div className="about-me-text-container">
        <div className="text-wrapper">
          <p>
            born into a family of artists, my journey in design started early,
            intertwined with a passion for art and technology. despite studying
            Economics & Business, my heart led me back to design.
          </p>
          <br />
          <p>
            as an Integrated Design Director I specialize in overseeing and
            coordinating all aspects of design, ensuring cohesion and
            consistency across different design disciplines such as graphic
            design, UI/UX design, branding, and art direction.
          </p>
          <br />
          <p>
            I represent the rebel generation, merging creativity with strategic
            business acumen. I thrive on projects blending innovation and
            practicality. with a Google UX Professional Certificate and a
            fluency in developer speak, I bridge the gap between design and
            technology. obsessed with self-improvement, I embrace the evolving
            landscape of user-centred design.
          </p>
        </div>
        <div className="properties">
          <div className="point">
            <h3>point of compass</h3>
            <p>
              I help companies build and enhance their products, with a focus on
              improving UX and performance, or refreshing their brand identity,
              using design principles and positive psychology.
            </p>
          </div>
          <div className="approach">
            <h3>appraoch</h3>
            <p>
              my design style goes beyond just techniques; it’s an intuitive art
              form rooted in understanding businesses’ needs.
            </p>
            <p>
              I prioritize human connection in all my work, maintaining empathy
              as the cornerstone of my approach.
            </p>
          </div>
          <div className="aim">
            <h3>aim</h3>
            <p>
              my mission is to seamlessly blend creativity with customer needs,
              crafting messages of strength, consistency, and originality.
            </p>
            <p>
              I’m dedicated to curating sleek, distinctive, and contemporary
              visual solutions.
            </p>
          </div>
        </div>
      </div>
      <div className="about-part-three-section">
        <div className="kis-logo">
          <p>
            {' '}
            keep it <span className="simp">simp</span>le
            <span className="dot">.</span>
          </p>
        </div>
        <h3 className="lit">literally.</h3>
        <p className="expression">
          keeping things clear wherever I can, even when it seems impossible.
        </p>
      </div>
    </div>
  )
}

export default PartThree
