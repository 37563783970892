import React from 'react'
import './blob.css'

const Blob = () => {
  return (
    <div className="blob">
      <div className="link-wrapper">
        <a
          href="https://www.instagram.com/salomeskv?igsh=aHljN2IyMmxrMXFo&utm_source=qr"
          className="top-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          instagram
        </a>
        <a
          href="https://www.behance.net/salomeskv"
          className="left-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          behance
        </a>
        <a
          href="https://www.linkedin.com/in/salomeskv?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          className="right-center"
          rel="noopener noreferrer"
        >
          linkedin
        </a>
      </div>
      <div className="bottom-center"></div>
    </div>
  )
}

export default Blob
