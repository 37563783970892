import React, { useState } from 'react'
import './navbar.css'
import { NavLink } from 'react-router-dom'
import { navbarItems } from '../../assets/data/navbarItems'
import Signature from '../../assets/images/ხელმოწერა.png'
import { whiteLogo } from '../../assets/images/logoWhite'

const Navbar = () => {
  const [menu, setMenu] = useState(false)

  const toggleMenu = () => {
    setMenu(!menu)
  }
  return (
    <div className="navbar-wrapper" id="myElement">
      <div className={`navbar-container ${menu ? 'added-bg' : 'remove-bg'}`}>
        <Menu className="navbar-list" />
        {menu ? (
          <div className="signature-image">{whiteLogo}</div>
        ) : (
          <img className="signature-image" alt="signature" src={Signature} />
        )}
        <div className="menu" onClick={toggleMenu}>
          <div
            className={`menu-icon ${menu ? 'active' : ''}`}
            onClick={toggleMenu}
          >
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
          <Menu
            className={`navbar-list-menu ${menu ? 'open added-bg' : 'remove-bg'}`}
            active={menu ? 'active-ul' : ''}
          />
        </div>
      </div>
    </div>
  )
}

const Menu = ({ className, active }) => {
  return (
    <div className={className}>
      <ul className={`navbar-list-menu-wrapper ${active}`}>
        {navbarItems.map((item, index) => {
          return (
            <NavLink
              key={index}
              to={`/${item}`}
              className={({ isActive }) =>
                isActive
                  ? 'navbar-list-item-navbar active-link-navbar'
                  : 'navbar-list-item-navbar'
              }
            >
              {item}
            </NavLink>
          )
        })}
      </ul>
      <div className="socials-links">
        <a
          href="https://www.instagram.com/salomeskv?igsh=aHljN2IyMmxrMXFo&utm_source=qr"
          target="_blank"
          rel="noopener noreferrer"
        >
          instagram
        </a>
        <a
          href="https://www.behance.net/salomeskv"
          target="_blank"
          rel="noopener noreferrer"
        >
          behance
        </a>
        <a
          href="https://www.linkedin.com/in/salomeskv?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          rel="noopener noreferrer"
        >
          linkedin
        </a>
      </div>
    </div>
  )
}

export default Navbar
