import React, { useState } from 'react'
import './partOne.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { S } from '../../assets/images/s'
import { A } from '../../assets/images/a'

const PartOne = () => {
  const sliderList = [
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373349/slider%20on%20landing/Screenshot_2022-08-29_at_3.40.02_PM_fzgnel.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373349/slider%20on%20landing/Mask_Group_8_fxtegu.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373348/slider%20on%20landing/Mask_Group_7_jhi929.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373348/slider%20on%20landing/Mask_Group_6_oktgkt.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373347/slider%20on%20landing/Mask_Group_5_xvhxlc.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373346/slider%20on%20landing/Mask_Group_4_svvzgs.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373346/slider%20on%20landing/Mask_Group_2_mtngkd.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373346/slider%20on%20landing/Mask_Group_3_nuk6o6.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373345/slider%20on%20landing/Mask_Group_1_iezpcj.jpg',
    'https://res.cloudinary.com/ddkum3qpt/image/upload/v1709373345/slider%20on%20landing/cover_for_slider_on_lending_lboxch.jpg',
  ]

  const [currentIndex, setCurrentIndex] = useState(0)

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderList.length)
  }

  return (
    <div id="start" className="about-part-one">
      <div className="about-image-container">
        <div className="about-image">
          <LazyLoadImage
            rel="preload preconnect dns-prefetch"
            src={sliderList[currentIndex]}
            effect="blur"
            beforeLoad={() => {
              const img = new Image()
              img.src = sliderList[currentIndex]
            }}
          />
        </div>
        <div className="image-footer">
          <p>highlights of the latest work</p>
          <div
            className="arrow"
            onClick={nextImage}
            style={{ cursor: 'pointer' }}
          ></div>
        </div>
      </div>
      <div className="char-content">
        <div className="s-char">{S}</div>
        <div className="a-char">{A}</div>
      </div>
    </div>
  )
}

export default PartOne
