import React, { useMemo } from 'react'
import './partFour.css'

const PRINCIPLE_DATA = [
  {
    header: 'P’RESPONSIBILITY',
    description:
      'I’m a deadline-driven professional who stays committed, never ghosting, and always sticking to the plan.',
    className: 'res',
  },
  {
    header: 'P’INSPIRATION',
    description:
      'I only take on projects I’m interested in. I treat each opportunity with deep thinking and exploration so that I may always create the best possible visual communication solution. I draw creative inspiration and strategic insights from various sources and translate my findings into artwork supported by pragmatic thinking.',
    className: 'ins',
  },
  {
    header: 'P’QUALITY',
    description:
      'my work is rooted in a dedication to quality. I strive for excellence in every project, pushing boundaries and paying meticulous attention to detail. “As the details are not the details, they Make the design.”\n with continuous growth, I aim to exceed expectations and deliver exceptional design solutions.',
    className: 'qua',
  },
  {
    header: 'P’INDIVIDUALITY.',
    description:
      'I prioritize personalized s olutions for each client, avoiding templates.\n every design and communication strategy is carefully crafted to captivate and influence, ensuring a lasting impact.',
    className: 'indi',
  },
]

const PartFour = () => {
  return (
    <div className="about-part-four">
      <div className="principles-wrapper">
        <div className="mobile-section">
          <PrinciplesSection />
        </div>
        <div className="principles-wrapper-p">
          <p>life is too short for poor user experience.</p>
        </div>
        <div className="principles-container">
          {PRINCIPLE_DATA.map((principle, index) => (
            <PrincipleBlock
              key={index}
              header={principle.header}
              description={principle.description}
              className={principle.className}
            />
          ))}
        </div>
      </div>
      <div className="pc-section">
        <PrinciplesSection />
      </div>
    </div>
  )
}

const PrincipleBlock = ({ header, description, className }) => {
  const newText = useMemo(() => description.split('\n'), [description])
  return (
    <div className={className}>
      <h3>{header}</h3>
      {newText.map((str, index) => (
        <React.Fragment key={index}>
          <p>{str}</p>
          <br />
        </React.Fragment>
      ))}
    </div>
  )
}

const PrinciplesSection = () => {
  return (
    <div className="principles-section">
      <div className="four-ps">
        <p className="four-ps-text">
          Four principles of mine (not marketing’s)
        </p>
        <div className="ps-char">
          <p>
            {' '}
            4P<span className="ps">s</span>
          </p>
        </div>
      </div>
      <p className="principle-text">
        all this stands for moulding organic <br /> ideas into smooth <br />{' '}
        creations.
      </p>
    </div>
  )
}

export default PartFour
