export const Lo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="102.421"
    height="98.383"
    viewBox="0 0 102.421 98.383"
  >
    <g
      id="Group_26"
      dataname="Group 26"
      transform="translate(-1657.5 -343.146)"
    >
      <path
        id="Path_3"
        dataname="Path 3"
        d="M498.827,836.15v.485H472.984l1.092-7.523V757.047c0-4.854-3.64-4.246-11.525-1.941v-.728l29.967-13.952-.607,5.823v78.616C491.912,831.419,492.761,834.816,498.827,836.15Z"
        transform="translate(1195.449 -396.456)"
        fill="#151515"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path_4"
        dataname="Path 4"
        d="M547.3,790.266c0,17.836-12.617,30.21-32.758,30.21s-32.756-11.889-32.756-30.21c0-17.834,12.617-30.208,32.756-30.208S547.3,771.947,547.3,790.266ZM530.56,800.457c0-16.014-6.307-38.338-20.745-38.338-8.25,0-11.282,6.915-11.282,18.2,0,15.771,6.432,38.094,20.745,38.094C527.527,818.412,530.56,811.377,530.56,800.457Z"
        transform="translate(1212.118 -379.447)"
        fill="#151515"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
);
