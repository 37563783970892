import React from 'react'
import './about.css'

import Navbar from '../components/shared/Navbar'
import PartOne from '../components/about/PartOne'
import PartTwo from '../components/about/PartTwo'
import Blob from '../components/shared/Blob'
import PartThree from '../components/about/PartThree'
import LandingPart from '../components/about/LandingPart'
import PartFour from '../components/about/PartFour'
import Footer from '../components/shared/Footer'

import img1 from '../assets/images/branding/1.jpg'
import img2 from '../assets/images/branding/3.jpg'
import img3 from '../assets/images/branding/2.jpg'

import uImg1 from '../assets/images/ui_ux/1.jpg'
import uImg2 from '../assets/images/ui_ux/2.jpg'
import uImg3 from '../assets/images/ui_ux/3.jpg'
import { Helmet } from 'react-helmet-async'

const About = () => {
  return (
    <div className="about-container">
      <Helmet>
        <title>Salomeskv</title>
        <meta
          name="description"
          content="Learn more about Salomeskv, a talented artist known for their captivating designs and creative vision. Discover the story behind Salomeskv's journey, their inspirations, and artistic philosophy. Explore a selection of Salomeskv's remarkable projects and get inspired by their passion for creativity and innovation."
        />
      </Helmet>
      <div className="blob-container">
        <Blob />
      </div>
      <PartOne />
      <Navbar />
      <PartTwo />
      <PartThree />
      <LandingPart
        title="Branding"
        img1={img1}
        img2={img2}
        img3={img3}
        text="solutions i offer"
        smText="view my projects"
        text1="at the heart of every successful brand lies its visual identity. my approach goes beyond surface aesthetics, delving deep into the product’s essence to create robust identity systems."
        text2="I strategize on how to effectively communicate with the target audience and ensure scalability for market trust."
        text3="with a meticulous, process-oriented mindset, I guide clients through every step of the branding journey. each project comes complete with custom brand guidelines, empowering teams to seamlessly apply and evolve their brand for ongoing success."
      />
      <LandingPart
        title="ui/ux"
        img1={uImg2}
        img2={uImg1}
        img3={uImg3}
        smText="view my projects"
        text1="I kickstart every project with a deep dive into business needs and client requirements. leveraging a range of UX design approaches,"
        text2="I prioritize compiling requirements, conducting competitive audits, and aligning with product development life cycle stages to lay a solid foundation."
        text3="my specialty lies in identifying and resolving UX issues, with a particular focus on responsiveness and accessibility."
      />
      <PartFour />
      <Footer isValid={true} id="start" />
    </div>
  )
}

export default About
