export const Me = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="176.455"
    height="63.717"
    viewBox="0 0 176.455 63.717"
  >
    <g
      id="Group_27"
      dataname="Group 27"
      transform="translate(-1779.5 -707.126)"
    >
      <path
        id="Path_5"
        dataname="Path 5"
        d="M519.534,760.452h21.471v8.759a30.028,30.028,0,0,1,9.364-7.8,25.708,25.708,0,0,1,11.4-2.316q7.184,0,11.372,2.542a18.908,18.908,0,0,1,6.846,7.572q5.417-5.876,9.875-8a25.587,25.587,0,0,1,11-2.117q9.648,0,15.064,5.734t5.416,17.94v37.689H598.3V786.274q0-4.068-1.577-6.045a6.927,6.927,0,0,0-5.743-3.108,8.131,8.131,0,0,0-6.532,2.938q-2.478,2.94-2.477,9.437v30.964H558.915V787.4a20.723,20.723,0,0,0-.453-5.368,7.336,7.336,0,0,0-2.551-3.645,6.8,6.8,0,0,0-4.25-1.385,8.2,8.2,0,0,0-6.523,3q-2.55,3-2.551,9.831v30.626H519.534Z"
        transform="translate(1260.466 -51.471)"
        fill="#151515"
        stroke="#151515"
        strokeWidth="1"
      />
      <path
        id="Path_6"
        dataname="Path 6"
        d="M645.8,796.162H599.695q.621,5.54,3,8.25a10.9,10.9,0,0,0,8.7,3.9,13.079,13.079,0,0,0,6.441-1.695,15.142,15.142,0,0,0,4.013-3.787l22.657,2.09q-5.2,9.043-12.542,12.97t-21.076,3.925q-11.923,0-18.759-3.361a27.54,27.54,0,0,1-11.329-10.68,32.235,32.235,0,0,1-4.493-17.2q0-14.068,9.013-22.77t24.889-8.7q12.884,0,20.342,3.9A26.329,26.329,0,0,1,641.9,774.3q3.9,7.405,3.9,19.267Zm-23.394-11.018q-.677-6.666-3.587-9.548a11.258,11.258,0,0,0-16.413,1.469q-2.094,2.71-2.656,8.08Z"
        transform="translate(1309.652 -51.471)"
        fill="#151515"
        stroke="#151515"
        strokeWidth="1"
      />
    </g>
  </svg>
);
