import React from 'react'
import './landingPart.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Carousel from './Carousel'
import ScrollingText from './ScrollingText'
const LandingPart = ({
  title,
  img1,
  img2,
  img3,
  smText,
  text1,
  text2,
  text3,
  text,
}) => {
  return (
    <div className="about-landing-part">
      <h3 className="expression-two">{text}</h3>
      <ScrollingText title={title} />
      <Carousel img1={img1} img2={img2} img3={img3} smText={smText} />
      <div className="texts">
        <p className="text-one">{text1}</p>
        <br />
        <p className="text-one">{text2}</p>
        <br />
        <p className="text-one">{text3}</p>
      </div>
    </div>
  )
}

export default LandingPart
