export const Skv = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="226.887"
    height="99.109"
    viewBox="0 0 226.887 99.109"
  >
    <g
      id="Group_28"
      dataname="Group 28"
      transform="translate(-1451.613 -981.391)"
    >
      <path
        id="Path_7"
        dataname="Path 7"
        d="M614.156,813.318l8.128-12.981h.243c-.485,10.555,10.435,17.955,20.869,17.955,6.672,0,10.193-2.671,10.193-8.373,0-7.036-5.34-10.555-16.137-12.01-18.442-2.184-22.2-13.224-22.2-20.139,0-11.04,10.191-17.712,25.113-17.712,9.948,0,16.984,3.033,21.352,7.279l-8.614,11.4h-.243c.849-9.218-7.643-16.863-16.863-16.863-8.007,0-8.856,5.461-8.856,8.007,0,7.038,5.7,10.071,14.315,11.648,11.284,2.184,22.324,7.158,22.324,19.532,0,11.163-9.948,19.413-24.993,19.413C629.565,820.476,618.887,817.32,614.156,813.318Z"
        transform="translate(838.111 258.798)"
        fill="#151515"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path_8"
        dataname="Path 8"
        d="M689.995,836.635v-2.064l-22.083-25.841h-.6v16.135c0,6.553.847,9.95,6.915,11.284v.485H648.381l1.092-7.523V757.047c0-4.854-3.641-4.246-11.527-1.941v-.728l29.965-13.952-.6,5.823v61.51l23.657-23.414c2.305-2.305,3.52-4.855-2.426-5.823l.243-.73h21.354l-.243.73c-5.1.6-13.347,3.518-23.78,13.951l-6.915,6.915,18.321,21.232c8.492,9.582,11.766,12.858,18.074,16.014Z"
        transform="translate(858.723 241.79)"
        fill="#151515"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path_9"
        dataname="Path 9"
        d="M740.484,760.447v.485c-3.884,1.456-6.432,3.763-11.283,14.2l-21.111,45.737h-.849l-25.6-52.168-5.217-8.25h31.544v.485c-6.066.73-11.406,3.64-6.308,14.075l12.981,26.328,12.253-26.328c4.731-10.435-.484-12.619-4.851-14.075v-.485Z"
        transform="translate(892.062 259.135)"
        fill="#151515"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <g
        id="Group_2"
        dataname="Group 2"
        transform="translate(1647.548 1005.761)"
      >
        <path
          id="Path_10"
          dataname="Path 10"
          d="M717.177,766.6l2.376-.855c.381,1.021,1.685,1.734,3.942,1.734,2.923,0,4.134-.974,4.11-2.327-.022-1.592-1.331-1.971-4.11-2.542-3.656-.76-6.057-1.829-6.008-4.87.048-2.708,2.447-4.821,6.008-4.821,2.923,0,5.013,1.329,5.726,3.421l-2.376.855c-.356-1.045-1.5-1.807-3.326-1.807-2.281,0-3.561,1.14-3.49,2.376.022,1.377,1.331,1.8,4.037,2.376,3.729.76,6.057,1.851,6.057,5.011,0,2.945-2.447,4.821-6.628,4.821C720.1,769.977,718.056,768.742,717.177,766.6Z"
          transform="translate(-717.177 -752.924)"
          fill="#151515"
          stroke="#151515"
          strokeWidth="1"
        />
      </g>
      <path
        id="Path_11"
        dataname="Path 11"
        d="M738.693,756.836c-.019.037-.037.071-.054.1l-4.668,9.338a1.162,1.162,0,0,1-2.1.041q-1.086-2.167-2.167-4.334l-2.525-5.051-.052-.1a.2.2,0,0,0-.017.125V769.1c0,.162,0,.162-.159.162h-2.038c-.138,0-.123.017-.123-.121V756.78c0-.86,0-1.723,0-2.583a1.16,1.16,0,0,1,.752-1.114.791.791,0,0,1,.28-.052c.424,0,.847,0,1.273,0a1.13,1.13,0,0,1,1.073.664c.233.459.463.922.692,1.383q2,3.989,3.99,7.981c.017.034.034.067.058.11.024-.047.043-.082.062-.12q2.329-4.659,4.659-9.317a1.176,1.176,0,0,1,.872-.687.629.629,0,0,1,.133-.013c.442,0,.886-.009,1.329,0a1.153,1.153,0,0,1,1.051.974,2.251,2.251,0,0,1,.015.248v14.868c0,.134,0,.134-.129.134h-2.057c-.151,0-.134.026-.134-.14v-6.9q0-2.635,0-5.271A.17.17,0,0,0,738.693,756.836Z"
        transform="translate(936.969 252.929)"
        fill="#151515"
        stroke="#151515"
        strokeWidth="1"
      />
    </g>
  </svg>
);
